import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout"
import ImageGalleryWithLightbox from "../../components/imageGalleryWithLightbox"
import FreieZimmer from "../../components/freieZimmer"

const AngebotAltersheim = ({data}) => {

  // Single Image Data
  const images = {
    image1: data.image1.childImageSharp.fluid,
    image2: data.image2.childImageSharp.fluid,
  }

  return (
    <Layout title="Angebot Altersheim" slogan="" headerImage={data.image1.childImageSharp.fluid}>
      <div className="grid grid-2">
        <div>
          <h4>Unser Altersheim</h4>
          <p>
            Es stehen 27 Einerzimmer und 1 Wohneinheit für Paare zur Verfügung. Alle sind mit einem Badezimmer ausgerüstet. Die Zimmer dürfen mit eigenen Möbeln eingerichtet werden. Die überschaubare familiäre Grösse und Atmosphäre ermöglichen einen raschen Kontakt untereinander. Die ruhige und zentrumsnahe Lage, sowie der neu gestaltete Garten, laden zu Spaziergängen ein.
          </p>
          <h4>Bewohnerinnen und Bewohner</h4>
          <p>
            Wir achten und respektieren die Persönlichkeit und die Privatsphäre jeder Bewohnerin und jedes Bewohners in unserem Altersheim. Menschlichkeit und Wärme in der täglichen Begegnung sind uns wichtig. Wir unterstützen Selbständigkeit und Eigenverantwortung. Gut informierte und zufriedene Heimbewohnerinnen und -bewohner sind uns wichtig. Motiviertes, fachlich gut ausgebildetes Personal betreut und unterstützt sie bedürfnisorientiert. Auch freiwillige Helferinnen engagieren sich zum Wohl der Bewohnerinnen und Bewohner.
          </p>  
          <h4>Personal und Heimleitung</h4>
          <p>
            Wir führen ein offenes Altersheim und schätzen einen guten Kontakt zu Angehörigen, Bekannten und zur Öffentlichkeit. Das Heim wird selbsttragend nach ökonomischen und ökologischen Grundsätzen geführt. Es weist ein ausgewogenes Preis-/Leistungsverhältnis auf. Die Heimleitung wird unterstützt durch die Heimkommission.Gerne zeigen wir Ihnen, nach telefonischer Vereinbarung, unverbindlich unser Haus und beantworten Ihre Fragen in einem persönlichen Gespräch.
          </p>

        </div>
        <div>
        <li className="formular-download-link formular-download-link--disabled" style={{fontSize: '1.2em', marginBottom: "16px"}}>Freie Zimmer: <FreieZimmer /></li>
          <ImageGalleryWithLightbox images={Object.values(images)} />
        </div>
      </div>
    </Layout>
  ) 
}

export default AngebotAltersheim

export const pageQuery = graphql`
  query {
    image1: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-angebot-altersheim-zimmer.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
    image2: file(sourceInstanceName: {eq: "assets"}, relativePath: { eq: "bannau-angebot-altersheim-zimmer-1.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1600) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    },
  }
`