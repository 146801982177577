import React, { useState, useEffect } from "react"

const FreieZimmer = (props) => {
    const [zimmerData, setZimmerData] = useState('0')
    useEffect(() => {
    var fetchUrl = "/freiezimmer.json" // props.location.origin + "/freiezimmer.json"
    // console.log("Identified current location as: " + props.location.origin)
    // console.log("Getting URL: " + fetchUrl)
    // get concerts data from .json file
    fetch(`${fetchUrl}`) // actual: https://dev11.lacreme.digital/concerts_data.json // local test/dummy: ../concerts_data_dummy.json
        .then(response => response.json()) // parse JSON from request
        .then(resultData => {
        // console.log("fetched data: " + resultData.value)
        setZimmerData(resultData.value)
        })
    })
    return (
        <span>{zimmerData}</span>
    )
}

export default FreieZimmer